import React, { useMemo, useState } from 'react';

import CloseIcon from '@material-ui/icons/Close';
import { workTagImageUpdateMisconceptions } from 'sdk';

import AddMisconceptionsDialog from 'pages/Teachers/InsightsV2/components/AddMisconceptionsDialog';
import { colors } from 'theme/palette';
import { notifyError, notifySuccess } from 'utils/notifications';

import AddButton from 'components/AddButton';
import ConfirmDialog from 'components/ConfirmDialog';
import Typography from 'components/Typography';

import styles from './styles.module.scss';

const MisconceptionMasteryLevel = ({
  workTagImageId,
  masteryLevel,
  refetchMasteryLevel
}) => {
  const { misconceptions, analysis } = masteryLevel;
  const misconceptionsToUse = useMemo(() => {
    return misconceptions.filter(
      (misconception) => misconception !== 'N/A - No misconception present'
    );
  }, [misconceptions]);

  console.log(analysis);

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const [misconceptionToDelete, setMisconceptionToDelete] = useState(null);
  const [misconceptionsToAdd, setMisconceptionsToAdd] = useState([]);

  const updateMisconceptions = async ({ newMisconceptions }) => {
    const { success } = await workTagImageUpdateMisconceptions({
      workTagImageId,
      data: { misconceptions: newMisconceptions }
    });

    if (success) {
      notifySuccess('Successfully updated misconceptions.');
      refetchMasteryLevel();
    } else {
      notifyError('Something went wrong. Please try again.');
    }
  };

  const handleDeleteClick = (misconception) => {
    setMisconceptionToDelete(misconception);
    setIsDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    const updatedMisconceptions = misconceptionsToUse.filter(
      (msc) => msc !== misconceptionToDelete
    );
    updateMisconceptions({ newMisconceptions: updatedMisconceptions });

    setMisconceptionToDelete(null);
    setIsDeleteConfirmOpen(false);
  };

  const openAddDialog = () => setIsAddDialogOpen(true);

  const confirmAdd = async () => {
    const updatedMisconceptions = Array.from(
      new Set([...misconceptionsToUse, ...misconceptionsToAdd])
    );

    updateMisconceptions({ newMisconceptions: updatedMisconceptions });

    setMisconceptionsToAdd([]);
    setIsAddDialogOpen(false);
  };

  const closeDialogs = () => {
    setMisconceptionToDelete(null);
    setIsDeleteConfirmOpen(false);
    setIsAddDialogOpen(false);
  };

  return (
    <>
      <div className={styles.misconceptionsContainer}>
        <Typography
          variant="H-TEXT-3"
          component="h5"
          className={styles.summaryData}
        >
          Misconceptions
        </Typography>
        <div className={styles.innerData}>
          {misconceptionsToUse.length === 0 &&
            'Bright work! No misconceptions.'}
          {misconceptionsToUse.map((misconception, index) => (
            <div key={index} className={styles.misconception}>
              <Typography variant="S-TEXT-1" className={styles.lineText}>
                {index + 1}. {misconception}
              </Typography>
              <CloseIcon
                className={styles.closeIcon}
                onClick={() => handleDeleteClick(misconception)}
              />
            </div>
          ))}
        </div>
        <div className={styles.buttonContainer}>
          <AddButton
            color="blue"
            className={styles.nextStepButton}
            onClick={openAddDialog}
          >
            <Typography
              variant="B-Text-4"
              component="span"
              className={styles.nextStepText}
            >
              Add Misconceptions
            </Typography>
          </AddButton>
        </div>
      </div>
      {isDeleteConfirmOpen && (
        <ConfirmDialog onClose={closeDialogs} onConfirm={confirmDelete}>
          <div>
            <Typography variant="B-Text-1" color={colors.grey1} align="center">
              Are you sure you want to delete this misconception?
            </Typography>
          </div>
        </ConfirmDialog>
      )}
      {isAddDialogOpen && (
        <AddMisconceptionsDialog
          isOpen={isAddDialogOpen}
          onConfirm={confirmAdd}
          onClose={closeDialogs}
          setMisconceptionsToAdd={setMisconceptionsToAdd}
        />
      )}
    </>
  );
};

export default MisconceptionMasteryLevel;
